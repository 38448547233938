.neonText {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
}

.neonTextSuccess {
  animation: flickerSuccess 1s ease-in-out infinite alternate;
  color: #fff;
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@keyframes flickerSuccess {
  0% {
    color: #fff;
    text-shadow: 0 0 10px #1de50d, 0 0 15px #1de50d, 0 0 20px #1de50d,
      0 0 25px #1de50d, 0 0 30px #1de50d, 0 0 35px #1de50d, 0 0 40px #1de50d,
      0 0 45px #1de50d;
  }
  ,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    color: gray;
    text-shadow: 0 0 15px #1de50d, 0 0 20px #1de50d, 0 0 25px #1de50d,
      0 0 30px #1de50d, 0 0 35px #1de50d, 0 0 40px #1de50d, 0 0 45px #1de50d,
      0 1 50px #1de50d;
  }
}
